@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;




.country-select button {
    padding: 5px 10px;
    border: thin solid rgba(77, 77, 77, 0.3);
    border-top-color: rgba(77, 77, 77, 0.3);
    border-top-style: solid;
    border-top-width: thin;
    border-right-color: rgba(77, 77, 77, 0.3);
    border-right-style: solid;
    border-right-width: thin;
    border-bottom-color: rgba(77, 77, 77, 0.3);
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-left-color: rgba(77, 77, 77, 0.3);
    border-left-style: solid;
    border-left-width: thin;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}